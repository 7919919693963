@import "./config";

.table-timeline-responsive {
  margin: 15px 5px;
  height: 800px;
  overflow-x: auto;

  .table-timeline {
    text-align: center;
    // cell-padding: 15;
    td,
    th {
      border: 1px solid black;
      padding: 4px;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .table-timeline {
    font-family: "Source Sans Pro";
  }

  .table-timeline thead {
    .position-sticky {
      position: sticky;
      top: 0;
      background-color: $btn-seen;
      color: $secondary-color;
    }
  }
}

.timeline-item-td {
  overflow-y: auto;
  height: 20px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .timeline-table-wrapper {
    width: 450px;
    margin: 0;
  }
}

@media screen and (max-width: 426px) {
  .timeline-table-wrapper {
    margin: 10px 10px;
    width: 300px;
  }
}

/* Responsive */

// @media (max-width: 767px) {
//     .fl-table {
//         display: block;
//         width: 100%;
//     }
//     .table-wrapper:before{
//         // content: "Scroll horizontally >";
//         display: block;
//         text-align: right;
//         font-size: 11px;
//         color: white;
//         padding: 0 0 10px;
//     }
//     .fl-table thead, .fl-table tbody, .fl-table thead th {
//         display: block;
//     }
//     .fl-table thead th:last-child{
//         border-bottom: none;
//     }
//     .fl-table thead {
//         float: left;
//     }
//     .fl-table tbody {
//         width: auto;
//         position: relative;
//         overflow-x: auto;
//     }
//     .fl-table td, .fl-table th {
//         padding: 20px .625em .625em .625em;
//         height: 60px;
//         vertical-align: middle;
//         box-sizing: border-box;
//         overflow-x: auto;
//         overflow-y: auto;
//         width: 120px;
//         font-size: 13px;
//         text-overflow: ellipsis;
//     }
//     .fl-table thead th {
//         text-align: left;
//         border-bottom: 1px solid #f7f7f9;
//     }
//     .fl-table tbody tr {
//         display: table-cell;
//     }
//     .fl-table tbody tr:nth-child(odd) {
//         background: none;
//     }
//     .fl-table tr:nth-child(even) {
//         background: transparent;
//     }
//     .fl-table tr td:nth-child(odd) {
//         background: #F8F8F8;
//         border-right: 1px solid #E6E4E4;
//     }
//     .fl-table tr td:nth-child(even) {
//         border-right: 1px solid #E6E4E4;
//     }
//     .fl-table tbody td {
//         display: block;
//         text-align: center;
//     }
// }
